<template>
    <div>
        <app-layout>
            <Header :title="$t('placement_exam_score_upload_title')" :is-filter="false"/>
            <HeaderMobile :title="$t('placement_exam_score_upload_title')" :is-filter="false"/>
            <div class="row data-form">
                <div class="col-12 col-md-12">
                    <b-button variant="primary" @click="openImportModal">{{$t('placement_exam_score_upload')}}</b-button>
                </div>
            </div>
            <CommonModal size="lg" ref="importModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('placement_exam_score_upload_title')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <import-score :placement_exam_id="$route.params.id" @hide="closeImportModal"></import-score>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import ImportScore from "./Import";
import CommonModal from "@/components/elements/CommonModal";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

export default {

    components: {
        AppLayout,
        ImportScore,
        CommonModal,
        HeaderMobile,
        Header
    },

    data() {
        return {

        }
    },
    metaInfo() {
        return {
            title: this.$t("placement_exam_score_upload_title")
        }
    },
    methods: {
        openImportModal(){
            if(this.checkPermission('placementexamscore_import')){
                this.$refs.importModal.$refs.commonModal.show()
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },

        closeImportModal(){
            this.$refs.importModal.$refs.commonModal.hide()
        }
    },

    created() {

    }
};
</script>
