import Api from '@/services/Index';

const Import = async (id, file) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/placement/exam/scores/import/' + id, file);
}

export default {
    Import
}
